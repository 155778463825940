























































import {Vue, Component} from "vue-property-decorator";
import {BusinessType} from "@/request/plat/BusinessType";

@Component({
    name: 'plat_businessType'
})
export default class plat_businessType extends Vue {
    requestPath: string = BusinessType.requestPath;
    businessType: Array<any> = [];
    firstType: Array<any> = [];
    secType: Array<any> = [];
    moditfyModal: any = {
        show: false,
        id: '',
        type: 0,
        name: ''
    };
    created() {
        BusinessType.businessType().then((body: any) => {
            if (body.code === 0) {
                this.businessType = body.data;
                if (this.businessType.length > 0) {
                    this.businessType[0].selected = true;
                    this.getTypes(this.businessType[0]);
                }
            }
        })
    }
    getTypes(item: any) {
        const msg = this.$Message.loading({
            content: '加载中...',
            duration: 0
        })
        this.businessType.forEach((j: any) => j.selected = j.btype === item.btype);
        BusinessType.getByType(item.btype).then((body: any) => {
            this.$Message.destroy()
            if (body.code === 0) {
                let data = body.data;
                this.firstType = data.filter((j: any) => j.type === 0);
                this.secType = data.filter((j: any) => j.type === 1);
            }
        })
    }
    createNew() {
        let selectedTypes = this.businessType.filter((j: any) => j.selected);
        if (selectedTypes.length === 0) {
            this.$Message.warning('请选择商业类型');
            return;
        }
        this.moditfyModal.businessType = selectedTypes[0].btype;
        this.moditfyModal.show = true;
        this.moditfyModal.type = 0;
        this.moditfyModal.id = '';
        this.moditfyModal.name = '';
    }
    moditfy(item: any) {
        this.moditfyModal.show = true;
        this.moditfyModal.type = item.type;
        this.moditfyModal.id = item.id;
        this.moditfyModal.name = item.name;
    }
    saveOrUpdate() {
        let params: any = {
            id: this.moditfyModal.id,
            businessType: this.moditfyModal.businessType,
            type: this.moditfyModal.type,
            name: this.moditfyModal.name
        }
        BusinessType.save(params).then((body: any) => {
            if (body.code === 0) {
                let selectedType = this.businessType.filter((j: any) => j.selected)[0];
                this.getTypes(selectedType);
            }
        })
    }
    del(id: string) {
        let self: any = this;
        this.$Modal.confirm({
            title: '提示',
            content: '确认删除吗',
            onOk() {
                BusinessType.del([id]).then((body: any) => {
                    if (body.code === 0) {
                        let selectedType = self.businessType.filter((j: any) => j.selected)[0];
                        self.getTypes(selectedType);
                    }
                })
            }
        });
    }
}
