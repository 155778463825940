import { render, staticRenderFns } from "./BusinessType.vue?vue&type=template&id=3318d14a&scoped=true&"
import script from "./BusinessType.vue?vue&type=script&lang=ts&"
export * from "./BusinessType.vue?vue&type=script&lang=ts&"
import style0 from "./BusinessType.vue?vue&type=style&index=0&id=3318d14a&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3318d14a",
  null
  
)

export default component.exports