import {BaseRequest} from "@/request/BaseRequest";

class BusinessType extends BaseRequest {

    public requestPath: string = '/plat/businessType';

    businessType(): any {
        return this.get(`${this.requestPath}/businessAll`)
    };

    getByType(type: number): any {
        return this.get(`${this.requestPath}/getAll?businessType=${type}`)
    }


}

const c = new BusinessType();
export {c as BusinessType};